import React from "react";
import Image from "next/image";
import Link from "next/link";
import { AppName } from "../../config";

const Navigation = ({}) => {
  return (
    <nav>
      <div className="navbar">
        <div className="flex-1">
          <div className="p-5">
            <Link href="/">
              <Image
                src="https://res.cloudinary.com/drzgzthur/image/upload/c_scale,w_797/v1678637849/DAX/DAX-logo-dark.png"
                height={75}
                width={150}
                alt="Dax Logo"
              />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
