export const AppName = "DAX";

export const TLDs = {
  featured: [
    "ai",
    "club",
    "design",
    "global",
    "guru",
    "life",
    "media",
    "news",
    "online",
    "photography",
    "rocks",
    "shop",
    "site",
    "solutions",
    "space",
    "store",
    "tech",
    "today",
    "website",
    "xyz",
  ],
};

// export const TLDs = [
//   "ac",
//   "academy",
//   "accountant",
//   "accountants",
//   "actor",
//   "adult",
//   "ag",
//   "agency",
//   "ai",
//   "airforce",
//   "am",
//   "amsterdam",
//   "apartments",
//   "app",
//   "archi",
//   "army",
//   "art",
//   "asia",
//   "associates",
//   "at",
//   "attorney",
//   "au",
//   "auction",
//   "auto",
//   "autos",
//   "baby",
//   "band",
//   "bar",
//   "barcelona",
//   "bargains",
//   "bayern",
//   "be",
//   "beauty",
//   "beer",
//   "berlin",
//   "best",
//   "bet",
//   "bid",
//   "bike",
//   "bingo",
//   "bio",
//   "biz",
//   "pl",
//   "black",
//   "blog",
//   "blue",
//   "boats",
//   "boston",
//   "boutique",
//   "build",
//   "builders",
//   "business",
//   "buzz",
//   "bz",
//   "ca",
//   "cab",
//   "cafe",
//   "camera",
//   "camp",
//   "capital",
//   "car",
//   "cards",
//   "care",
//   "careers",
//   "cars",
//   "casa",
//   "cash",
//   "casino",
//   "catering",
//   "cc",
//   "center",
//   "ceo",
//   "ch",
//   "charity",
//   "chat",
//   "cheap",
//   "church",
//   "city",
//   "cl",
//   "claims",
//   "cleaning",
//   "clinic",
//   "clothing",
//   "cloud",
//   "club",
//   "cn",
//   "co",
//   "in",
//   "jp",
//   "kr",
//   "nz",
//   "uk",
//   "za",
//   "coach",
//   "codes",
//   "coffee",
//   "college",
//   "com",
//   "ag",
//   "au",
//   "br",
//   "bz",
//   "cn",
//   "co",
//   "es",
//   "ky",
//   "mx",
//   "pe",
//   "ph",
//   "pl",
//   "tw",
//   "community",
//   "company",
//   "computer",
//   "condos",
//   "consulting",
//   "contact",
//   "contractors",
//   "cooking",
//   "cool",
//   "country",
//   "coupons",
//   "courses",
//   "credit",
//   "creditcard",
//   "cricket",
//   "cruises",
//   "cymru",
//   "cz",
//   "dance",
//   "date",
//   "dating",
//   "de",
//   "deals",
//   "degree",
//   "delivery",
//   "democrat",
//   "dental",
//   "dentist",
//   "design",
//   "dev",
//   "diamonds",
//   "digital",
//   "direct",
//   "directory",
//   "discount",
//   "dk",
//   "doctor",
//   "dog",
//   "domains",
//   "download",
//   "earth",
//   "education",
//   "email",
//   "energy",
//   "engineer",
//   "engineering",
//   "enterprises",
//   "equipment",
//   "es",
//   "estate",
//   "eu",
//   "events",
//   "exchange",
//   "expert",
//   "exposed",
//   "express",
//   "fail",
//   "faith",
//   "family",
//   "fan",
//   "fans",
//   "farm",
//   "fashion",
//   "film",
//   "finance",
//   "financial",
//   "in",
//   "fish",
//   "fishing",
//   "fit",
//   "fitness",
//   "flights",
//   "florist",
//   "fm",
//   "football",
//   "forsale",
//   "foundation",
//   "fr",
//   "fun",
//   "fund",
//   "furniture",
//   "futbol",
//   "fyi",
//   "gallery",
//   "games",
//   "garden",
//   "gay",
//   "in",
//   "gg",
//   "gifts",
//   "gives",
//   "glass",
//   "global",
//   "gmbh",
//   "gold",
//   "golf",
//   "graphics",
//   "gratis",
//   "green",
//   "gripe",
//   "group",
//   "gs",
//   "guide",
//   "guru",
//   "hair",
//   "haus",
//   "health",
//   "healthcare",
//   "hockey",
//   "holdings",
//   "holiday",
//   "homes",
//   "horse",
//   "hospital",
//   "host",
//   "house",
//   "tw",
//   "immo",
//   "immobilien",
//   "in",
//   "inc",
//   "in",
//   "industries",
//   "info",
//   "pl",
//   "ink",
//   "institute",
//   "insure",
//   "investments",
//   "io",
//   "irish",
//   "ist",
//   "istanbul",
//   "it",
//   "jetzt",
//   "jewelry",
//   "jobs",
//   "jp",
//   "kaufen",
//   "kids",
//   "kim",
//   "kitchen",
//   "kiwi",
//   "kr",
//   "ky",
//   "la",
//   "land",
//   "lat",
//   "law",
//   "lawyer",
//   "lease",
//   "legal",
//   "lgbt",
//   "life",
//   "lighting",
//   "limited",
//   "limo",
//   "live",
//   "llc",
//   "loan",
//   "loans",
//   "london",
//   "love",
//   "ltd",
//   "ltda",
//   "luxury",
//   "maison",
//   "makeup",
//   "management",
//   "market",
//   "marketing",
//   "mba",
//   "me",
//   "uk",
//   "media",
//   "melbourne",
//   "memorial",
//   "men",
//   "menu",
//   "miami",
//   "mobi",
//   "moda",
//   "moe",
//   "money",
//   "monster",
//   "mortgage",
//   "motorcycles",
//   "movie",
//   "ms",
//   "mx",
//   "nagoya",
//   "name",
//   "navy",
//   "kr",
//   "net",
//   "ag",
//   "au",
//   "br",
//   "bz",
//   "cn",
//   "co",
//   "in",
//   "ky",
//   "nz",
//   "pe",
//   "ph",
//   "pl",
//   "network",
//   "news",
//   "ninja",
//   "nl",
//   "no",
//   "co",
//   "es",
//   "pe",
//   "nrw",
//   "nyc",
//   "okinawa",
//   "one",
//   "onl",
//   "online",
//   "org",
//   "ag",
//   "au",
//   "cn",
//   "es",
//   "in",
//   "ky",
//   "nz",
//   "pe",
//   "ph",
//   "pl",
//   "uk",
//   "page",
//   "paris",
//   "partners",
//   "parts",
//   "party",
//   "pe",
//   "pet",
//   "ph",
//   "photography",
//   "photos",
//   "pictures",
//   "pink",
//   "pizza",
//   "pl",
//   "place",
//   "plumbing",
//   "plus",
//   "poker",
//   "porn",
//   "press",
//   "pro",
//   "productions",
//   "promo",
//   "properties",
//   "protection",
//   "pub",
//   "pw",
//   "quebec",
//   "quest",
//   "racing",
//   "kr",
//   "realestate",
//   "recipes",
//   "red",
//   "rehab",
//   "reise",
//   "reisen",
//   "rent",
//   "rentals",
//   "repair",
//   "report",
//   "republican",
//   "rest",
//   "restaurant",
//   "review",
//   "reviews",
//   "rich",
//   "rip",
//   "rocks",
//   "rodeo",
//   "run",
//   "ryukyu",
//   "sale",
//   "salon",
//   "sarl",
//   "school",
//   "schule",
//   "science",
//   "se",
//   "security",
//   "services",
//   "sex",
//   "sg",
//   "sh",
//   "shiksha",
//   "shoes",
//   "shop",
//   "shopping",
//   "show",
//   "singles",
//   "site",
//   "ski",
//   "skin",
//   "soccer",
//   "social",
//   "software",
//   "solar",
//   "solutions",
//   "space",
//   "storage",
//   "store",
//   "stream",
//   "studio",
//   "study",
//   "style",
//   "supplies",
//   "supply",
//   "support",
//   "surf",
//   "surgery",
//   "sydney",
//   "systems",
//   "tax",
//   "taxi",
//   "team",
//   "tech",
//   "technology",
//   "tel",
//   "tennis",
//   "theater",
//   "theatre",
//   "tickets",
//   "tienda",
//   "tips",
//   "tires",
//   "today",
//   "tokyo",
//   "tools",
//   "tours",
//   "town",
//   "toys",
//   "trade",
//   "training",
//   "travel",
//   "tube",
//   "tv",
//   "tw",
//   "uk",
//   "university",
//   "uno",
//   "us",
//   "vacations",
//   "vc",
//   "vegas",
//   "ventures",
//   "vet",
//   "viajes",
//   "video",
//   "villas",
//   "vin",
//   "vip",
//   "vision",
//   "vodka",
//   "vote",
//   "voto",
//   "voyage",
//   "wales",
//   "watch",
//   "webcam",
//   "website",
//   "wedding",
//   "wiki",
//   "win",
//   "wine",
//   "work",
//   "works",
//   "world",
//   "ws",
//   "wtf",
//   "xxx",
//   "xyz",
//   "yachts",
//   "yoga",
//   "yokohama",
//   "zone",
// ];
