import React from "react";
import Head from "next/head";
import { AppName } from "../../config";
import { Inter } from "@next/font/google";
import styles from "../../styles/Common.module.css";

import Navigation from "../Navigation";
import Footer from "../Footer";

const inter = Inter({ subsets: ["latin"] });

const Layout = ({ children }) => {
  const titleContent = `${AppName} | AI Domain Name Generator`;
  const description =
    "Looking for a perfect domain name? Discover DAX, the AI-powered startup that generates a list of available domain names based on your idea or keyword. Simplify your online journey today.";
  return (
    <>
      <Head>
        <title>{titleContent}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* OG Meta Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={titleContent} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`https://getdax.com`} />
        <meta property="og:site_name" content={AppName} />
        <meta property="og:image" content={`/meta/fb.png`} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:site" content="@getdax" /> */}
        <meta name="twitter:title" content={titleContent} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="/meta/twitter.png" />

        <link rel="icon" href="/favicon.ico" />
        <script
          src="https://beamanalytics.b-cdn.net/beam.min.js"
          data-token="73e8a87e-df62-461b-88cb-2ca3edd71579"
          async
        ></script>
      </Head>
      <div className={styles.sudoBody}>
        <Navigation />
        <main className={styles.main}>{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
