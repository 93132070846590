import React from "react";
import Image from "next/image";
import Link from "next/link";

const Footer = ({}) => {
  return (
    <footer className="footer footer-center p-10 bg-base-200 text-base-content rounded">
      <div className="grid grid-flow-col gap-4">
        <Link href="/about" className="link link-hover">
          About Us
        </Link>
        <Link href="/faq" className="link link-hover">
          FAQ
        </Link>
        <Link href="/terms-of-use" className="link link-hover">
          Terms
        </Link>
        <Link href="/privacy-policy" className="link link-hover">
          Privacy
        </Link>
        {/* <Link href="/featured" className="link link-hover">
          Featured On
        </Link> */}
      </div>
      <div>
        <Link href="/">
          <Image
            src="/DAX-logo-light.png"
            height={75}
            width={150}
            alt="Dax Logo"
          />
        </Link>
      </div>
      <div>
        <p>Copyright © 2023 - All right reserved by DAX</p>
      </div>
    </footer>
  );
};

export default Footer;
