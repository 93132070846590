import React, { useState, useEffect } from "react";
import Link from "next/link";
import axios from "axios";
import { parseCookies, setCookie } from "nookies";
import styles from "../styles/Common.module.css";
import swearjar from "swearjar";

import Layout from "../components/Layout";

const getDomains = async ({ prompt, keywords }) => {
  try {
    const { data } = await axios.post(`/api/magic`, { prompt, keywords });
    let error = null;

    if (data?.data?.length < 1) {
      error =
        "Oops, looks like we could not come up with a domain name for this prompt. Please try a different prompt.";
    }
    return { domains: data, error };
  } catch (err) {
    const domains = [];
    let error = true; // TODO: Return type of error

    if (err?.code === 502) {
      error =
        "Oops, it appears the server is currently overloaded, please try again soon.";
    }
    return { domains, error };
  }
};

export default function Home() {
  const [prompt, setPrompt] = useState("");
  const [promptHasBadWord, setPromptHasBadWord] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [responseError, setResponseError] = useState(null);
  const [hasExceededLimit, setHasExceededLimit] = useState(false);
  const [promptKeywords, setPromptKeywords] = useState({
    1: "",
    2: "",
    3: "",
  });
  const [keywordsHasBadWord, setKeywordsHasBadWord] = useState([
    false,
    false,
    false,
  ]);
  const [domains, setDomains] = useState([]);

  const doesPromptHaveBadWords = (prompt) => {
    const hasBadWord = swearjar.profane(prompt);
    setPromptHasBadWord(hasBadWord);
  };

  const doesKeywordHaveBadWord = (kw, i) => {
    const hasBadWord = swearjar.profane(kw);
    let tempArray = [...keywordsHasBadWord];
    tempArray[i] = hasBadWord;
    setKeywordsHasBadWord(tempArray);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setResponseError(false);

    const cookies = parseCookies();
    let gcCookie = cookies?.gc || 0;

    if (gcCookie > 10) {
      setHasExceededLimit(true);
      if (typeof window !== "undefined") {
        window.beam("/custom-events/exceeded_generation_limit");
      }
      return;
    }

    if (typeof window !== "undefined") {
      window.beam("/custom-events/generate_btn_clicked");
    }

    let keywords = [];
    if (!prompt) {
      setHasError(true);
      return;
    }
    setIsSubmitting(true);
    setHasError(false);
    keywords = Object.values(promptKeywords);
    keywords = keywords.filter(Boolean);

    if (keywords.length > 0) {
      if (typeof window !== "undefined") {
        window.beam("/custom-events/prompt_included_keywords");
      }
    }

    const { domains, error } = await getDomains({ prompt, keywords });
    if (error) {
      setResponseError(error);
      setIsSubmitting(false);
      return;
    }

    setCookie(null, "gc", ++gcCookie, {
      maxAge: 60 * 60 * 1000,
      path: "/",
    });

    setDomains(domains?.data);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (!domains?.length > 0) return;

    const body = document.querySelector("#scrollToDomains");
    if (!body) return;

    body.scrollIntoView({
      behavior: "smooth",
    });
  }, [domains]);

  const handleKeywordChange = (e) => {
    const keywordItem = e.target.dataset.keyword;
    const updateKeyword = { [keywordItem]: e.target.value };
    setPromptKeywords({ ...promptKeywords, ...updateKeyword });
  };

  return (
    <Layout>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col justify-center items-center w-full md:w-6/12 mb-10">
          <h2
            className={`text-3xl text-left w-full text-light-purple font-bold mb-5`}
            style={{
              background:
                "linear-gradient(0deg, rgba(195,100,224,1) 0%, rgba(228,101,108,1) 60%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            No More Writers Block
          </h2>
          <h1 className="text-6xl font-bold">
            Find your next <br></br>domain with the power of{" "}
            <span className={styles.shimmerAI}>AI!</span>
          </h1>
        </div>

        <div className="w-full md:w-6/12">
          <form
            onSubmit={handleOnSubmit}
            className="justify-center"
            id="ideaPromptForm"
          >
            <div className="form-control">
              <label className="label">
                <span className="label-text font-bold text-2xl text-white">
                  Your idea
                </span>
              </label>
              <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onBlur={() => doesPromptHaveBadWords(prompt)}
                maxLength={1000}
                rows="3"
                className={`textarea bg-mute-purple ${
                  hasError || promptHasBadWord ? "textarea-error" : ""
                }`}
                placeholder="Describe your company or website idea"
              ></textarea>
              <label className="label">
                <span className="label-text text-error">
                  {hasError && `Please enter an idea or company`}
                  {promptHasBadWord && `Please do not use any bad words`}
                </span>
                <span className="label-text-alt text-white">
                  {1000 - prompt.length} Remaining
                </span>
              </label>

              <label className="label">
                <span className="label-text font-bold text-2xl text-white">
                  Enter Keywords (Optional)
                </span>
              </label>

              <ol
                type="1"
                className="list-inside pl-5 w-full"
                style={{ listStyle: "revert" }}
              >
                {Array(3)
                  .fill()
                  .map((item, i) => (
                    <li key={i + 1}>
                      <input
                        value={promptKeywords[i + 1]}
                        onChange={handleKeywordChange}
                        onBlur={() =>
                          doesKeywordHaveBadWord(promptKeywords[i + 1], i)
                        }
                        type="text"
                        data-keyword={i + 1}
                        className={`input input-bordered bg-mute-purple w-full max-w-xs my-2 ml-5 ${
                          keywordsHasBadWord[i] ? "textarea-error" : ""
                        }`}
                      />
                      {keywordsHasBadWord[i] && (
                        <label className="label py-0">
                          <span className="label-text text-error ml-5">
                            Please do not use any bad words
                          </span>
                        </label>
                      )}
                    </li>
                  ))}
              </ol>
            </div>
            {!hasExceededLimit ? (
              <div className="flex justify-end my-5">
                {!isSubmitting ? (
                  <button
                    disabled={
                      promptHasBadWord || keywordsHasBadWord.some((el) => el)
                    }
                    type="submit"
                    form="ideaPromptForm"
                    className="btn btn-lg bg-light-orange hover:bg-secondary-focus text-white w-full md:w-fit"
                  >
                    🚀 Generate Now
                  </button>
                ) : (
                  <a
                    type=""
                    form="ideaPromptForm"
                    className="btn btn-lg loading w-full md:w-fit text-white"
                  >
                    🚀 Generating
                  </a>
                )}
              </div>
            ) : (
              <div className="mt-5 flex justify-end">
                <div className="alert alert-warning shadow-lg">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current flex-shrink-0 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                    <span>
                      Generations Limit Exceeded. Come back in 1 hour.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </form>
          {responseError && (
            <div className="mt-5 flex justify-end">
              <div className="alert alert-error shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>
                    {responseError === true
                      ? `There was an issue generating your domains. Please try again.`
                      : responseError}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className="flex flex-col justify-center items-center w-full"
        id="scrollToDomains"
      >
        {domains?.length > 0 && (
          <div className="mt-10 flex flex-col justify-center items-center w-full">
            <h1 className="text-3xl font-bold">Here&apos;s we what found 🌎</h1>
            <h1 className="">All domains are available ✅</h1>

            <div className="items-start justify-items-start w-full md:w-6/12 my-10">
              <table className="table-fixed w-full z-10">
                <tbody>
                  {domains.map((domain, index) => (
                    <tr key={index} className="border-solid border-sky-500">
                      <td
                        className="border border-r-0 p-5"
                        style={{ borderWidth: "0.5px 0 0.5px 0.5px" }}
                      >
                        {domain}
                      </td>
                      <td
                        className="border border-l-0 text-right"
                        style={{ borderWidth: "0.5px 0.5px 0.5px 0" }}
                      >
                        <Link
                          href={`https://www.godaddy.com/domainsearch/find?checkAvail=1&segment=repeat&domainToCheck=${domain}`}
                          onClick={() =>
                            window.beam("/custom-events/buy_btn_clicked")
                          }
                          target="_blank"
                          rel="nofollow"
                          className="btn btn-sm btn-outline hover:bg-light-orange hover:btn-outline-light-orange text-white mr-5"
                        >
                          Buy
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <div className="hidden md:flex flex-col justify-center items-center w-full mt-20">
        <h1 className="text-3xl font-bold">Need inspiration?</h1>
        <h1 className="text-xl font-bold mt-2">
          Check out these trending topics for available domain names 📈
        </h1>

        <div className="flex flex-col justify-center items-center w-full lg:w-8/12 mt-10">
          <div className="footer flex flex-row flex-wrap justify-between flex-grow gap-4 mb-10">
            <div>
              <span className="footer-title">Sports</span>
              <Link
                href={"topics/sports-betting"}
                prefetch={false}
                className="link link-hover"
              >
                Sports Betting
              </Link>
              <Link
                href={"topics/sports-news"}
                prefetch={false}
                className="link link-hover"
              >
                Sport News
              </Link>
              <Link
                href={"topics/esports"}
                prefetch={false}
                className="link link-hover"
              >
                Esports
              </Link>
              <Link
                href={"topics/trading-cards"}
                prefetch={false}
                className="link link-hover"
              >
                Trading Cards
              </Link>
            </div>
            <div>
              <span className="footer-title">Technology</span>
              <Link
                href={"topics/ai"}
                prefetch={false}
                className="link link-hover"
              >
                AI
              </Link>
              <Link
                href={"topics/coding"}
                prefetch={false}
                className="link link-hover"
              >
                Coding
              </Link>
              <Link
                href={"topics/women-in-tech"}
                prefetch={false}
                className="link link-hover"
              >
                Women in Tech
              </Link>
              <Link
                href={"topics/3d-printing"}
                prefetch={false}
                className="link link-hover"
              >
                3D Printing
              </Link>
            </div>
            <div>
              <span className="footer-title">Food</span>
              <Link
                href={"topics/slow-cooking"}
                prefetch={false}
                className="link link-hover"
              >
                Slow Cooking
              </Link>
              <Link
                href={"topics/bbq"}
                prefetch={false}
                className="link link-hover"
              >
                BBQ
              </Link>
              <Link
                href={"topics/recipes"}
                prefetch={false}
                className="link link-hover"
              >
                Recipes
              </Link>
              <Link
                href={"topics/food-reviews"}
                prefetch={false}
                className="link link-hover"
              >
                Food Reviews
              </Link>
            </div>
            <div>
              <span className="footer-title">Music</span>
              <Link
                href={"topics/concerts"}
                prefetch={false}
                className="link link-hover"
              >
                Concerts
              </Link>
              <Link
                href={"topics/instruments"}
                prefetch={false}
                className="link link-hover"
              >
                Instruments
              </Link>
              <Link
                href={"topics/audiophiles"}
                prefetch={false}
                className="link link-hover"
              >
                Audiophiles
              </Link>
              <Link
                href={"topics/k-pop"}
                prefetch={false}
                className="link link-hover"
              >
                K-Pop
              </Link>
            </div>
            <div>
              <span className="footer-title">Business</span>
              <Link
                href={"topics/branding"}
                prefetch={false}
                className="link link-hover"
              >
                Branding
              </Link>
              <Link
                href={"topics/payroll"}
                prefetch={false}
                className="link link-hover"
              >
                Payroll
              </Link>
              <Link
                href={"topics/marketing"}
                prefetch={false}
                className="link link-hover"
              >
                Marketing
              </Link>
              <Link
                href={"topics/economics"}
                prefetch={false}
                className="link link-hover"
              >
                Economics
              </Link>
            </div>
            <div>
              <span className="footer-title">Travel</span>
              <Link
                href={"topics/cheap-flights"}
                prefetch={false}
                className="link link-hover"
              >
                Cheap Flights
              </Link>
              <Link
                href={"topics/luggage"}
                prefetch={false}
                className="link link-hover"
              >
                Luggage
              </Link>
              <Link
                href={"topics/rentals"}
                prefetch={false}
                className="link link-hover"
              >
                Rentals
              </Link>
              <Link
                href={"topics/tour-guides"}
                prefetch={false}
                className="link link-hover"
              >
                Tour Guides
              </Link>
            </div>
          </div>
        </div>

        {/* Pop Culture Sports World Baseball Classic Trading Cards Business
        Technology Online Sports Betting Succession Formula 1 March Madness LIV
        Golf UFC TikTok GPT-4 Artificial Intelligence */}
      </div>
    </Layout>
  );
}
